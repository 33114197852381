import { CurrencyCode as CurrencyCodeType } from "@smartrr/shared/currencyCode";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { CurrencyCode } from "@smartrr/shared/shopifyGraphQL/api";
import { getSymbolFromCurrency } from "@smartrr/shared/utils/formatMoney";
import { isEqual, merge } from "lodash";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { LoyaltySections, defaultTiers, initialDefaultTiers } from "../libs/utils/constants";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { LoyaltyForm } from "./form";
import { useMemo } from "react";
import { RewardItem } from "../components/ShowLoyalty/LoyaltyRewards/LoyaltyRewardsTable/store";
import { generateNewIncentive } from "../components/ShowLoyalty/LoyaltyRewards/modals/utils";
import { DeepPartial } from "redux";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { getNextHighestTierToAdd } from "../libs/utils/getNextHighestTierToAdd";

interface LoyaltyStore {
  isLoading: boolean;
  showError: boolean;
  errorMessage: string;

  selectedTab: number;

  input: LoyaltyForm.Program.Type;
  compareInput: LoyaltyForm.Program.Type;

  rewards: LoyaltyApi.Program.Type | null;

  currency: CurrencyCodeType;

  filter: RewardItem.RewardType | null;

  analytics: {
    general: LoyaltyApi.Analytics.Type | null;
  };

  actions: {
    create(): Promise<void>;
    enable(): Promise<void>;
    initialize(): Promise<void>;
    discardChanges(): void;

    analytics: {
      fetch(): Promise<void>;
    };

    tabs: {
      update(selectedTab: LoyaltySections): void;
    };

    otp: {
      update(newOtpEnabled: boolean): void;
      enable(): Promise<void>;
    };

    events: {
      addEvent(eventType: LoyaltyApi.Enum.Event.Type): void;
      updateEvent({ index, points }: { index: number; points: number }): void;
      removeEvent(index: number): void;
    };

    currencyMultiplier: {
      updateCurrencyMultipler(code: CurrencyCodeType, value: number): void;
    };

    units: {
      updateSingular(singular: string): void;
      updatePlural(plural: string): void;
    };

    currency: {
      update(newCurrency: CurrencyCodeType): void;
    };

    updateDescription(description: string): void;

    marketplace: {
      findAndRemoveById(id: string): void;
      filter: {
        set(rewardType: RewardItem.RewardType): void;
        reset(): void;
      };
      product: {
        add(rewardProduct: LoyaltyForm.RewardProduct.Type): void;
        select(selected: LoyaltyForm.RewardProduct.Type[]): void;
        update(index: number, product: LoyaltyForm.RewardProduct.Type): void;
        updateById(id: string, rewardProduct: LoyaltyForm.RewardProduct.Type): void;
        remove(index: number): void;
        removeById(id: string): void;
        save(changes: Partial<LoyaltyValidation.Referral.Type>): Promise<void>;
      };

      incentive: {
        add(incentive: DeepPartial<LoyaltyValidation.Incentive.Type>): void;
        update(index: number, incentive: LoyaltyValidation.Incentive.Type): void;
        updateById(id: string, incentive: LoyaltyValidation.Incentive.Type): void;
        remove(index: number): void;
        removeById(id: string): void;
        save(changes: Partial<LoyaltyValidation.Referral.Type>): Promise<void>;
      };
    };

    bonus: {
      toggle(): void;
      update(bonus: LoyaltyValidation.Bonus.Type): void;
    };

    referral: {
      toggle(): void;
      update(referral: LoyaltyValidation.Referral.Type): void;
    };

    settings: {
      update(newSettings: LoyaltyValidation.Settings.Type): void;
    };

    tiers: {
      toggle(): void;
      add(): void;
      update(id: string, newTier: LoyaltyApi.Tier.Type, index: number): void;
      remove(index: number): void;
      reorder(): void;
      initializeTiers(): void;
      save(changes: Partial<LoyaltyValidation.Referral.Type>): Promise<void>;
    };

    save(): Promise<void>;
  };

  internal: {
    whileLoading<Type>(fn: () => Promise<Type>): Promise<Type | null>;
    initializeWith(rewards: LoyaltyApi.Program.Type): void;
  };
}

const initialInput: LoyaltyForm.Program.Type = {
  id: "",
  status: "DISABLED",
  otpEnabled: false,
  description: "",
  organizationId: "",
  rewardUnitNameSingular: "point",
  rewardUnitNamePlural: "points",
  events: [],
  currencies: [],
  variants: [],
  incentives: [],
  bonus: null,
  referralProgram: null,
  rewardSettings: {
    limitUse: 0,
    stackableDiscountCodes: false,
  },
  tiers: initialDefaultTiers,
  tiersEnabled: false,
};

const useLoyaltyStore = create<LoyaltyStore>()(
  immer((set, get) => ({
    isLoading: false,
    showError: false,
    errorMessage: "",

    selectedTab: 0,

    input: { ...initialInput },
    compareInput: { ...initialInput },

    rewards: null,

    currency: CurrencyCode.Usd,

    filter: null,

    analytics: {
      general: null,
    },

    actions: {
      async create() {
        const internal = get().internal;

        await internal.whileLoading(async () => {
          const response = await typedFrontendVendorApi.postReq("/reward-program/create");
          if (response.type === "error") {
            set({
              showError: true,
              rewards: null,
            });
          }
          if (response.type !== "success") {
            return;
          }

          get().internal.initializeWith(response.body);
        });
      },

      async enable() {
        const internal = get().internal;

        await internal.whileLoading(async () => {
          const response = await typedFrontendVendorApi.postReq("/reward-program/enable");
          if (response.type === "error") {
            set({
              showError: true,
              rewards: null,
            });
          }
          if (response.type !== "success") {
            return;
          }

          get().internal.initializeWith(response.body);
        });
      },

      async initialize() {
        const internal = get().internal;

        await internal.whileLoading(async () => {
          const response = await typedFrontendVendorApi.getReq("/reward-program", { cache: "no-cache" });
          if (response.type === "error") {
            set({
              showError: true,
              rewards: null,
            });
          }
          if (response.type !== "success") {
            return;
          }

          get().internal.initializeWith(response.body);
        });
      },

      discardChanges(): void {
        set({
          showError: false,
          input: get().compareInput,
        });
        get().actions.tiers.initializeTiers();
      },

      tabs: {
        update(selectedTab: LoyaltySections) {
          set(draft => {
            draft.selectedTab = selectedTab;
          });
        },
      },

      otp: {
        update(newOtpEnabled: boolean): void {
          if (get().rewards?.otpEnabled) {
            return;
          }

          set(draft => {
            draft.input.otpEnabled = newOtpEnabled;
          });
        },
        async enable(): Promise<void> {
          if (!get().input.otpEnabled) {
            return;
          }
          await get().internal.whileLoading(async () => {
            const response = await typedFrontendVendorApi.putReq("/rewards/enable-otp");

            if (response.type !== "success") {
              return;
            }

            get().internal.initializeWith(response.body);
          });
        },
      },

      events: {
        addEvent(eventType: LoyaltyApi.Enum.Event.Type): void {
          for (const event of get().input.events) {
            if (event.type === eventType) {
              return;
            }
          }
          set(draft => {
            draft.input.events.push({
              type: eventType,
              pointsEarned: 0,
            });
          });
        },

        updateEvent({ index, points }: { index: number; points: number }): void {
          if (get().input.events[index]) {
            set(draft => {
              draft.input.events[index].pointsEarned = points;
            });
          }
        },

        removeEvent(index: number) {
          if (get().input.events[index]) {
            set(draft => {
              draft.input.events.splice(index, 1);
            });
          }
        },
      },

      currencyMultiplier: {
        updateCurrencyMultipler(code: CurrencyCodeType, value: number): void {
          set(draft => {
            const index = draft.input.currencies.findIndex(c => c.currency === code);
            if (index === -1) {
              draft.input.currencies.push({
                currency: code,
                multiplier: value,
              });
            } else {
              draft.input.currencies[index] = {
                currency: code,
                multiplier: value,
              };
            }
          });
        },
      },

      currency: {
        update(newCurrency: CurrencyCodeType): void {
          set({
            currency: newCurrency,
          });
        },
      },

      units: {
        updateSingular(singular: string): void {
          set(draft => {
            draft.input.rewardUnitNameSingular = singular;
          });
        },
        updatePlural(plural: string): void {
          set(draft => {
            draft.input.rewardUnitNamePlural = plural;
          });
        },
      },

      updateDescription(description: string): void {
        set(draft => {
          draft.input.description = description;
        });
      },

      marketplace: {
        findAndRemoveById(id: string): void {
          const input = get().input;
          let wasRemoved = false;
          const allVariantIds = input.variants.map(vnt => vnt.id);
          const allIncentiveIds = input.incentives.map(inc => inc.id);
          // Checking if id exists within list of variants
          if (allVariantIds.includes(id)) {
            this.product.removeById(id);
            wasRemoved = true;
          }

          // Checking if id exists within list of incentives
          if (allIncentiveIds.includes(id)) {
            this.incentive.removeById(id);
            wasRemoved = true;
          }

          if (!wasRemoved) {
            throw new Error(`Error removing id: ${id}`);
          }
        },
        filter: {
          set(rewardType: RewardItem.RewardType): void {
            set({
              filter: rewardType,
            });
          },
          reset(): void {
            set({
              filter: null,
            });
          },
        },
        product: {
          add(rewardProduct: LoyaltyForm.RewardProduct.Type): void {
            set(draft => {
              draft.input.variants = [...draft.input.variants, rewardProduct];
            });
          },
          select(selected: LoyaltyForm.RewardProduct.Type[]): void {
            set(draft => {
              draft.input.variants = selected;
            });
          },
          update(index: number, product: LoyaltyForm.RewardProduct.Type): void {
            if (get().input.variants[index]) {
              set(draft => {
                draft.input.variants[index] = product;
              });
            }
          },
          updateById(id: string, rewardProduct: LoyaltyForm.RewardProduct.Type): void {
            const variants = get().input.variants;
            set(draft => {
              draft.input.variants = variants.map(vnt => {
                if (vnt.variantId === id) {
                  return rewardProduct;
                }
                return vnt;
              });
            });
          },
          remove(index: number): void {
            if (get().input.variants[index]) {
              set(draft => {
                draft.input.variants.splice(index, 1);
              });
            }
          },
          removeById(id: string): void {
            const productIndex = get().input.variants.findIndex(vnt => vnt.id === id);
            if (productIndex !== -1) {
              this.remove(productIndex);
            }
          },
          async save(changes: Partial<LoyaltyApi.Program.Type>) {
            const response = await typedFrontendVendorApi.putReq("/reward-program/variants", {
              reqBody: changes,
            });

            if (response.type === "error") {
              set(draft => {
                draft.showError = true;
                draft.errorMessage = response.message;
              });
            }
          },
        },
        incentive: {
          add(incentive: DeepPartial<LoyaltyValidation.Incentive.Type>): void {
            const tiersEnabled = get().input.tiersEnabled;
            const baseIncentive = generateNewIncentive(tiersEnabled);
            const mergedIncentive = merge(baseIncentive, incentive) satisfies LoyaltyValidation.Incentive.Type;
            set(draft => {
              draft.input.incentives.push(mergedIncentive);
            });
          },
          update(index: number, incentive: LoyaltyValidation.Incentive.Type): void {
            if (get().input.incentives[index]) {
              set(draft => {
                draft.input.incentives[index] = incentive;
              });
            }
          },
          updateById(id: string, incentive: LoyaltyValidation.Incentive.Type): void {
            const incentives = get().input.incentives;
            set(draft => {
              draft.input.incentives = incentives.map(inc => {
                if (inc.id === id) {
                  return incentive;
                }
                return inc;
              });
            });
          },
          remove(index: number): void {
            if (get().input.incentives[index]) {
              set(draft => {
                draft.input.incentives.splice(index, 1);
              });
            }
          },
          removeById(id: string) {
            const incentiveIndex = get().input.incentives.findIndex(inc => inc.id === id);
            if (incentiveIndex !== -1) {
              this.remove(incentiveIndex);
            }
          },
          async save(changes: Partial<LoyaltyApi.Program.Type>) {
            const response = await typedFrontendVendorApi.putReq("/reward-program/incentives", {
              reqBody: changes,
            });

            if (response.type === "error") {
              set(draft => {
                draft.showError = true;
                draft.errorMessage = response.message;
              });
            }
          },
        },
      },

      bonus: {
        toggle() {
          const input = get().input;

          if (input.bonus) {
            set(draft => {
              draft.input.bonus = null;
            });
            return;
          }

          const parsedInput = LoyaltyValidation.Program.schema.safeParse(get().rewards);
          if (parsedInput.success && parsedInput.data.bonus) {
            set(draft => {
              draft.input.bonus = parsedInput.data.bonus;
            });
          } else {
            set(draft => {
              draft.input.bonus = { every: 0, givePoints: 0 };
            });
          }
        },

        update(bonus: LoyaltyValidation.Bonus.Type): void {
          set(draft => {
            draft.input.bonus = bonus;
          });
        },
      },

      referral: {
        toggle(): void {
          const input = get().input;

          if (input.referralProgram) {
            set(draft => {
              draft.input.referralProgram = null;
            });
            return;
          }

          const parsedInput = LoyaltyForm.Program.schema.safeParse(get().rewards);
          if (parsedInput.success && parsedInput.data.referralProgram) {
            set(draft => {
              draft.input.referralProgram = parsedInput.data.referralProgram;
            });
          } else {
            set(draft => {
              draft.input.referralProgram = {
                description: "",
                name: "",
                pointsInReward: 1,
                priceRule: {
                  type: "fixed_amount",
                  value: "-1",
                },
              };
            });
          }
        },
        update(referral: LoyaltyValidation.Referral.Type): void {
          set(draft => {
            draft.input.referralProgram = referral;
          });
        },
      },

      settings: {
        update(newSettings: LoyaltyValidation.Settings.Type): void {
          if (LoyaltyValidation.Settings.schema.safeParse(newSettings).success) {
            set(draft => {
              draft.input.rewardSettings = newSettings;
            });
          }
        },
      },

      tiers: {
        toggle() {
          const input = get().input;

          if (input.tiersEnabled) {
            set(draft => {
              draft.input.tiersEnabled = false;
            });

            return;
          }

          set(draft => {
            // Setting to empty array to capture initial tiers changes
            draft.compareInput.tiers = [];
            draft.input.tiersEnabled = true;
          });
        },

        add() {
          const { tiers } = get().input;
          // Used to avoid adding tiers with the same rank
          const highestTier = tiers.reduce((maxTier, currentTier) => {
            return currentTier.tierRank > maxTier.tierRank ? currentTier : maxTier;
          });

          const nextHighestTier = getNextHighestTierToAdd(defaultTiers, tiers);

          const tierToAdd = {
            ...nextHighestTier,
            tierRank: highestTier.tierRank + 1,
          };

          set(draft => {
            draft.input.tiers = [...tiers, tierToAdd];
          });

          this.reorder();
        },

        update(id: string, newTier: LoyaltyApi.Tier.Type, index: number): void {
          const { tiers } = get().input;
          // Getting index as zustand can only update based on index
          // If there is no id then we use the passed in index
          if (id) {
            const findIndex = tiers.findIndex(tier => tier.id === id);
            set(draft => {
              draft.input.tiers[findIndex] = newTier;
            });
          } else {
            set(draft => {
              draft.input.tiers[index] = newTier;
            });
          }
        },

        remove(index: number) {
          const input = get().input;
          const { tiers } = get().actions;
          set(draft => {
            draft.input.tiers = input.tiers.toSpliced(index, 1);
          });

          tiers.reorder();
        },

        reorder() {
          const { tiers } = get().input;
          const reorderedTiers = tiers
            .map(tier => ({ ...tier }))
            .sort((a, b) => a.minimumPoints - b.minimumPoints);

          // Update the tierRank for each tier in the reordered array
          for (const [index, tier] of reorderedTiers.entries()) {
            tier.tierRank = index + 1;
          }

          set(draft => {
            draft.input.tiers = reorderedTiers;
          });
        },

        /**
         * This method ensures that default tiers are initialized
         * if there are no saved tiers. It also prevents the save bar
         * from appearing as the tiers state is the same for input and
         * compareInput
         */
        initializeTiers() {
          const { tiers } = get().input;
          if (tiers.length) {
            return;
          }
          // Only getting first 3 as these should be displayed on toggle
          set(draft => {
            draft.compareInput.tiers = initialDefaultTiers;
            draft.input.tiers = initialDefaultTiers;
          });
        },

        async save(changes: Partial<LoyaltyApi.Program.Type>) {
          const programId = get().input.id;

          if (changes.tiersEnabled) {
            const tiersEnabledPutResponse = await typedFrontendVendorApi.putReq("/rewards/enable-tiers");

            if (tiersEnabledPutResponse.type === "error") {
              set(draft => {
                draft.showError = true;
                draft.errorMessage = tiersEnabledPutResponse.message;
              });
            }
          }

          if (changes.tiers) {
            get().actions.tiers.reorder();
            changes.tiers = get().input.tiers;
            const tiersPutResponse = await typedFrontendVendorApi.putReq(`/reward-program/${programId}/tiers`, {
              reqBody: {
                tiers: changes.tiers,
              },
            });

            if (tiersPutResponse.type === "success") {
              return;
            }

            if (tiersPutResponse.type === "error") {
              set(draft => {
                draft.showError = true;
                draft.errorMessage = tiersPutResponse.message;
              });
            }
          }
        },
      },

      async save() {
        await get().internal.whileLoading(async () => {
          const parsedLoyalty = LoyaltyForm.Program.schema.safeParse(get().compareInput);
          if (!parsedLoyalty.success) {
            return;
          }

          const parsedForm = LoyaltyForm.Program.schema.safeParse(get().input);
          if (!parsedForm.success) {
            set(draft => {
              draft.showError = true;
            });
            return;
          }

          const changes: Partial<LoyaltyApi.Program.Type> = {};

          for (const key of Object.keys(get().input)) {
            if (isEqual(parsedForm.data[key], parsedLoyalty.data[key])) {
              continue;
            }
            changes[key] = parsedForm.data[key];
          }

          if (changes.tiers ?? changes.tiersEnabled) {
            const tiers = get().actions.tiers;
            await tiers.save(changes);
          }

          if (changes.variants) {
            const { product } = get().actions.marketplace;
            await product.save(changes);
          }

          if (changes.incentives) {
            const { incentive } = get().actions.marketplace;
            await incentive.save(changes);
          }

          const response = await typedFrontendVendorApi.postReq("/reward-program", {
            reqBody: changes,
          });

          if (response.type !== "success") {
            return;
          }

          if (get().filter) {
            set({
              filter: null,
            });
          }

          get().internal.initializeWith(response.body);
        });
      },
      analytics: {
        async fetch() {
          try {
            const loyaltyAnalyticsFetchRes = await typedFrontendVendorApi.getReq("/reward-program/analytics");

            if (loyaltyAnalyticsFetchRes.type !== "success") {
              set(draft => {
                draft.showError = true;
                draft.errorMessage = loyaltyAnalyticsFetchRes.message;
              });
              return;
            }
            set({
              analytics: {
                general: loyaltyAnalyticsFetchRes.body,
              },
            });
          } catch (error) {
            set(draft => {
              draft.showError = true;
              draft.errorMessage = error;
            });
          }
        },
      },
    },
    internal: {
      async whileLoading<Type>(fn: () => Promise<Type>): Promise<Type | null> {
        if (get().isLoading) {
          return null;
        }
        set({
          isLoading: true,
        });
        const response = await fn();
        set({
          isLoading: false,
        });
        return response;
      },
      initializeWith(rewards: LoyaltyApi.Program.Type) {
        const parsedInput = LoyaltyForm.Program.schema.safeParse(rewards);

        if (!parsedInput.success) {
          set({
            showError: true,
          });
          return;
        }

        set({
          showError: false,
          errorMessage: "",
          rewards,
          input: parsedInput.data,
          compareInput: parsedInput.data,
        });

        const { tiers } = get().actions;
        tiers.initializeTiers();
      },
    },
  }))
);

const initialStoreState = useLoyaltyStore.getState();

export const LoyaltyStoreAccess = {
  useLoading() {
    return useLoyaltyStore(state => state.isLoading);
  },
  useStatus() {
    return useLoyaltyStore(state => state.input.status);
  },
  useActions() {
    return useLoyaltyStore(state => state.actions);
  },
  useRewards() {
    return useLoyaltyStore(state => state.rewards);
  },
  useValue<Key extends keyof LoyaltyForm.Program.Type>(key: Key) {
    return useLoyaltyStore(state => state.input[key]);
  },
  useInput() {
    return useLoyaltyStore(state => state.input);
  },
  useShowError() {
    return useLoyaltyStore(state => state.showError);
  },
  useErrors() {
    const showError = useLoyaltyStore(state => state.showError);
    const errorMessage = useLoyaltyStore(state => state.errorMessage);

    return {
      showError,
      errorMessage,
    };
  },
  useSelectedTab() {
    return useLoyaltyStore(state => state.selectedTab);
  },
  useCurrency() {
    const currency = useLoyaltyStore(state => state.currency);
    const symbol = getSymbolFromCurrency(currency);
    return { currency, symbol };
  },
  useSavedValues() {
    return useLoyaltyStore(state => state.compareInput);
  },

  useHasFormChanged() {
    const compare = useLoyaltyStore(state => state.compareInput);
    const input = useLoyaltyStore(state => state.input);

    return useMemo(() => {
      return !isEqual(compare, input);
    }, [compare, input]);
  },
  useCurrencyMultiplierValue(code: CurrencyCodeType) {
    const currencyMultipliers = useLoyaltyStore(state => state.input.currencies);
    const multiplier = currencyMultipliers.find(c => c.currency === code);

    return useMemo(() => {
      return multiplier?.multiplier ?? 1;
    }, [multiplier]);
  },

  useRewardItems() {
    const variants = useLoyaltyStore(state => state.input.variants);
    const incentives = useLoyaltyStore(state => state.input.incentives);

    const filter = useLoyaltyStore(state => state.filter);

    return useMemo(() => {
      let arr: RewardItem.Type[] = [];
      switch (filter) {
        case "INCENTIVE": {
          arr = incentives;
          break;
        }
        case "PRODUCT": {
          arr = variants;
          break;
        }
        default: {
          arr = [...incentives, ...variants];
          break;
        }
      }

      /** sorting rows by
       * lowest ☞ hightest (costInPoints)
       * then
       * Products ☞ Incentives (rewardType)
       */
      const sortedArr = [...arr].sort((a, b) => {
        if (a.costInPoints !== b.costInPoints) {
          return a.costInPoints - b.costInPoints;
        }

        return a.rewardType === "PRODUCT" ? 1 : -1;
      });

      return sortedArr;
    }, [incentives, variants, filter]) satisfies RewardItem.Type[];
  },

  useRewardUnits() {
    const pluralUnit = useLoyaltyStore(state => state.rewards?.rewardUnitNamePlural) ?? "";
    const singularUnit = useLoyaltyStore(state => state.rewards?.rewardUnitNameSingular) ?? "";
    return useMemo(() => ({ singularUnit, pluralUnit }), [singularUnit, pluralUnit]);
  },
  useIsTiersEnabled(): boolean {
    const { tieredLoyalty: isTiersEnabledFF } = useSmartrrFlags();
    const tiersEnabled = useLoyaltyStore(state => state.input.tiersEnabled);
    const allTiers = useLoyaltyStore(state => state.input.tiers);
    return useMemo(() => {
      return !!(allTiers?.length && tiersEnabled && isTiersEnabledFF);
    }, [allTiers, tiersEnabled, isTiersEnabledFF]);
  },

  useTiers() {
    const isTierLoyaltyEnabled = this.useIsTiersEnabled();
    const allTiers = useLoyaltyStore(state => state.input.tiers);
    return { isTierLoyaltyEnabled, allTiers };
  },

  useAnalytics() {
    const { loyaltyAnalytics: isLoyaltyAnalyticsEnabled } = useSmartrrFlags();
    // TODO: Expand to tiers when added
    const { general } = useLoyaltyStore(state => state.analytics);

    // TODO: Remove flag when loyaltyAnalytics / loyalty-analytics is removed
    if (!isLoyaltyAnalyticsEnabled) {
      return { general: null };
    }
    return { general };
  },

  testing: {
    store() {
      return useLoyaltyStore.getState;
    },
    actions() {
      return { actions: useLoyaltyStore.getState().actions, internal: useLoyaltyStore.getState().internal };
    },
    reset(loyalty?: LoyaltyApi.Program.Type) {
      useLoyaltyStore.setState(initialStoreState);
      if (loyalty) {
        useLoyaltyStore.getState().internal.initializeWith(loyalty);
      }
    },
  },
};
