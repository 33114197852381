import { SelectOption } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";
import { LoyaltyForm } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store/form";
import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";

export function generateVariantSelectOptions(
  vnts: IPurchasableVariant[],
  selectedIds?: string[]
): SelectOption[] {
  return vnts
    .filter(vnt => !vnt.isDraftOrArchived)
    .map(vnt => {
      const skuStr = vnt.sku ? ` (${vnt.sku})` : "";
      const variantNameStr = isDefaultVariant(vnt.purchasableVariantName)
        ? "Default"
        : vnt.purchasableVariantName;
      return {
        label: `${variantNameStr}${skuStr}`,
        value: vnt.shopifyId ?? "",
        disabled: selectedIds?.includes(vnt.shopifyId ?? ""),
      };
    });
}

export function generateNewRewardProduct(
  variant: IPurchasableVariant,
  purchaseable: IPurchasable,
  tiersEnabled: boolean
): LoyaltyForm.RewardProduct.Type {
  return {
    id: v4(),
    rewardType: "PRODUCT",
    costInPoints: 1,
    shopifyId: variant.shopifyId!,
    variantId: variant.id,
    hidden: false,
    subscriberOnly: !tiersEnabled,
    tiers: [],
    variant: {
      id: variant.id,
      productName: purchaseable.purchasableName,
      variantImages: [variant.purchasableVariantImages?.[0] ?? purchaseable.purchasableImages?.[0] ?? ""],
      variantName: variant.purchasableVariantName ?? "",
      shopifyId: variant.shopifyId!,
    },
  };
}

export function generateNewIncentive(tiersEnabled: boolean): LoyaltyValidation.Incentive.Type {
  return {
    id: v4(),
    rewardType: "INCENTIVE",
    color: "rgb(244,244,244)",
    costInPoints: 1,
    priceRule: {
      type: "fixed_amount",
      value: "-1",
    },
    hidden: false,
    subscriberOnly: !tiersEnabled,
    tiers: [],
  };
}

/**
 * Takes in current value and returns all tiers
 * that are greater than or equal to that tier
 * in rank
 */
export function getRewardItemTierIds(tiers: LoyaltyApi.Tier.Type[], currentTierRank: number): string[] {
  return tiers.filter(tier => tier.id && tier.tierRank >= currentTierRank).map(tier => tier.id as string);
}

/**
 * Takes in tiers and tier ids and returns
 * relevant tier objects
 */
export const getTiersFromIds = (
  tiers: LoyaltyApi.Tier.Type[],
  tierIds: LoyaltyApi.Tier.Type["id"][]
): LoyaltyApi.Tier.Type[] => {
  const tierIdsClone = cloneDeep(tierIds).filter((id): id is string => !!id);
  return tiers.filter(tier => tier.id && tierIdsClone.includes(tier.id));
};

/**
 * Takes in new tier's rank,
 * current tiers, product, and zustand update
 * method.
 * If item is not in tiers, set to the relevant tiers.
 *
 * Returns the updated tiers.
 */
export function setTiersForProducts(
  tierRank: number,
  tiers: LoyaltyApi.Tier.Type[],
  product: LoyaltyApi.RewardProduct.Type,
  updateTier: (tierId: string, tier: LoyaltyApi.Tier.Type, index: number) => void
): LoyaltyApi.Tier.Type[] | [] {
  if (!tiers.length) {
    return [];
  }

  const newTiers = [...tiers].filter(tier => tier.tierRank >= tierRank);
  const isProductInTiers = tiers.some(tier => tier.products?.some(tierProduct => tierProduct === product.id));

  if (!isProductInTiers) {
    for (const [index, tier] of newTiers.entries()) {
      if (tier.id && !newTiers.includes(tier)) {
        const newProducts = [product.id];
        updateTier(tier.id, { ...tier, products: newProducts }, Number(index));
      }
      if (tier.id && tier.products) {
        updateTier(tier.id, { ...tier, products: [...tier.products, product.id] }, Number(index));
      }
    }
  }
  return newTiers.length ? newTiers : [];
}

/**
 * Takes in new tier's rank,
 * current tiers, incentive, and zustand update
 * method.
 * If item is not in tiers, set to the relevant tiers.
 *
 * Returns the updated tier ids.
 */
export function setTiersForIncentives(
  tierRank: number,
  tiers: LoyaltyApi.Tier.Type[],
  incentive: LoyaltyApi.Incentive.Type,
  updateTier: (tierId: string, tier: LoyaltyApi.Tier.Type, index: number) => void
): string[] | [] {
  if (!tiers.length) {
    return [];
  }

  const newTierIds = getRewardItemTierIds(tiers, tierRank);
  const isIncentiveInTiers = tiers.some(tier =>
    tier.incentives?.some(tierIncentive => tierIncentive === incentive.id)
  );

  if (!isIncentiveInTiers) {
    for (const [index, tier] of tiers.entries()) {
      if (tier.id && !newTierIds.includes(tier.id)) {
        const newIncentives = [incentive.id];
        updateTier(tier.id, { ...tier, incentives: newIncentives }, Number(index));
      }
      if (tier.id && tier.incentives) {
        updateTier(tier.id, { ...tier, incentives: [...tier.incentives, incentive.id] }, Number(index));
      }
    }
  }

  return newTierIds.length ? newTierIds : [];
}
