import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { LoyaltyValidation } from "@smartrr/shared/interfaces/loyalty/validation";
import { z } from "zod";

export namespace LoyaltyForm {
  export namespace RewardProduct {
    export const schema = LoyaltyValidation.Variant.schema.merge(
      z.object({
        variant: LoyaltyApi.RewardProduct.schema.shape.variant,
      })
    );
    export type Type = z.infer<typeof schema>;
  }

  export namespace Program {
    export const schema = LoyaltyValidation.Program.schema
      .omit({
        variants: true,
      })
      .merge(
        z.object({
          status: LoyaltyApi.Program.schema.shape.status,
          variants: z.array(RewardProduct.schema),
          otpEnabled: LoyaltyApi.Program.schema.shape.otpEnabled,
          tiersEnabled: LoyaltyApi.Program.schema.shape.tiersEnabled,
        })
      );
    export type Type = z.infer<typeof schema>;
  }
}
