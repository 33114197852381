import { z } from "zod";
import { ShopifyGidNamespace, getShopifyGidPrefix } from "../../utils/ensureShopifyGid";
import { CurrencyCode } from "../../shopifyGraphQL/api";
import { isFinite } from "lodash";

export namespace CommonApi {
  export namespace CurrencyCodeSchema {
    export const code = Object.values(CurrencyCode) as [Uppercase<keyof typeof CurrencyCode>];
    export const schema = z.enum(code);
  }

  export namespace ShopifyEntitySchema {
    export function schema<ShopifyId extends ShopifyGidNamespace>(idType: ShopifyId) {
      return z.object({
        createdDate: z.string().datetime(),
        updatedDate: z.string().datetime(),
        deletedAt: z.string().datetime().nullable(),
        id: z.string().uuid(),
        shopifyId: z.string().includes(getShopifyGidPrefix(idType), {
          message: `shopifyId must include "gid://shopify/${idType}/".`,
        }),
        receivedFromShopifyDate: z.string().datetime().nullable(),
        shopifyUpdateDate: z.string().datetime(),
      });
    }
  }
  export namespace PresentmentPriceSchema {
    export const schema = z.object({
      price: z
        .string()
        .min(1, { message: "Price required." })
        .refine(value => !isFinite(+value), {
          message: "Invalid number",
        }),
      priceCurrency: CurrencyCodeSchema.schema,
    });
  }

  export interface WithCount<Type> {
    data: Exclude<Type, null | undefined>[];
    count: number;
  }

  export namespace BigIntSchema {
    export const schema = z.string().refine(s => z.coerce.number().gte(0).safeParse(s).success, {
      message: "Money should be a string and greater than 0",
    });
  }

  export namespace DiscountSchema {
    export const discountTypes = ["FIXED", "PERCENTAGE", "PRICE"] as const;
    export const discountTypeSchema = z.enum(discountTypes);
    export const fixedDiscountSchema = z.object({
      type: z.literal(discountTypeSchema.Values.FIXED),
      amount: BigIntSchema.schema,
      currency: CommonApi.CurrencyCodeSchema.schema,
    });
    export const percentageDiscountSchema = z.object({
      type: z.literal(discountTypeSchema.Values.PERCENTAGE),
      amount: z.number().min(0).max(100),
    });
    export const priceDiscountSchema = z.object({
      type: z.literal(discountTypeSchema.Values.PRICE),
      amount: BigIntSchema.schema,
      currency: CommonApi.CurrencyCodeSchema.schema,
    });

    export const discountSchema = fixedDiscountSchema.or(percentageDiscountSchema).or(priceDiscountSchema);
  }

  export type CurrencyCode = z.infer<typeof CurrencyCodeSchema.schema>;
  export type PresentmentPrice = z.infer<typeof PresentmentPriceSchema.schema>;
  export type Discount = z.infer<typeof DiscountSchema.discountSchema>;
}
