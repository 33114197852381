import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";
import { RewardItem } from "../../components/ShowLoyalty/LoyaltyRewards/LoyaltyRewardsTable/store";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { VariantApi } from "@smartrr/shared/interfaces/variant/api";
import { BadgeStatusTitle, renderProductStatus, renderProductStatusTitle } from "@vendor-app/utils/displayUtils";
import { BadgeStatusValue } from "@shopify/polaris";
import { sortTiersByRank } from "@smartrr/shared/utils/sortBy";

enum RewardVisibility {
  HIDDEN = "Hidden",
  VISIBLE = "Visible",
}

export enum LoyaltySections {
  PROGRAM,
  REWARDS,
  TIERS,
  REFERRALS,
  CURRENCIES,
}

export const MAX_TIERS = 5;

export const loyaltyTabs = [
  {
    id: "program",
    content: "Program",
    accessibilityLabel: "Loyalty Program",
    panelID: "loyalty-program",
  },
  {
    id: "rewards",
    content: "Rewards",
    accessibilityLabel: "Loyalty Rewards",
    panelID: "loyalty-rewards",
  },
  {
    id: "tiers",
    content: "Tiers",
    accessibilityLabel: "Loyalty Tiers",
    panelID: "loyalty-tiers",
  },
  {
    id: "referrals",
    content: "Referrals",
    accessibilityLabel: "Loyalty Referrals",
    panelID: "loyalty-referrals",
  },
  {
    id: "currencies",
    content: "Currencies",
    accessibilityLabel: "Loyalty Currencies",
    panelID: "loyalty-currencies",
  },
];

export const defaultTiers: LoyaltyApi.Tier.Type[] = [
  {
    tierName: "Bronze",
    tierColor: "#CD7F32",
    tierRank: 1,
    minimumPoints: 0,
    incentives: [],
    products: [],
  },
  {
    tierName: "Silver",
    tierColor: "#B9BCBD",
    tierRank: 2,
    minimumPoints: 250,
    incentives: [],
    products: [],
  },
  {
    tierName: "Gold",
    tierColor: "#FFD700",
    tierRank: 3,
    minimumPoints: 500,
    incentives: [],
    products: [],
  },
  {
    tierName: "Platinum",
    tierColor: "#55523E",
    tierRank: 4,
    minimumPoints: 1000,
    incentives: [],
    products: [],
  },
  {
    tierName: "Diamond",
    tierColor: "#3F708C",
    tierRank: 5,
    minimumPoints: 2500,
    incentives: [],
    products: [],
  },
];

/**
 * Selecting the first three tiers from defaults (Bronze, Silver, and Gold)
 */
export const initialDefaultTiers: LoyaltyApi.Tier.Type[] = defaultTiers.slice(0, 3);

export const getEventNamesBasedOnType = (
  type: LoyaltyApi.Enum.Event.Type,
  currencySymbol: string
): string | undefined => {
  const map = {
    ACCOUNT_CREATED: "Account creation",
    BILLING_SUCCESS: `Transaction (per ${currencySymbol} spend)`,
  };

  return map[type];
};

export const discountNamesBasedOnType: {
  [key in LoyaltyApi.Enum.Discount.Type]: string;
} = {
  fixed_amount: "Fixed Amount",
  percentage: "Percentage",
};

export function isRewardItemProduct(row: RewardItem.Type): row is LoyaltyApi.RewardProduct.Type {
  return row.rewardType === "PRODUCT";
}
export const getRewardIncentiveBadgeTitle = (hidden: boolean) => {
  return hidden ? RewardVisibility.HIDDEN : RewardVisibility.VISIBLE;
};

export const getRewardItemBadgeStatusAndTitle = (
  vnt: IPurchasableVariant | VariantApi.Variant,
  hidden: boolean
) => {
  return { title: getRewardItemBadgeTitle(vnt, hidden), status: getRewardBadgeStatus(vnt, hidden) };
};

const getRewardItemBadgeTitle = (vnt: IPurchasableVariant | VariantApi.Variant, hidden: boolean): string => {
  const title = renderProductStatusTitle(vnt);
  if (!title) {
    return "Invalid";
  }
  if (title === BadgeStatusTitle.OutOfStock || title === BadgeStatusTitle.Deleted) {
    return title;
  }
  if (title === BadgeStatusTitle.Active && !hidden) {
    return RewardVisibility.VISIBLE;
  }

  if (title === BadgeStatusTitle.DraftOrArchived || hidden) {
    return RewardVisibility.HIDDEN;
  }
  return title;
};

const getRewardBadgeStatus = (
  vnt: IPurchasableVariant | VariantApi.Variant,
  hidden: boolean
): BadgeStatusValue => {
  const status = renderProductStatus(vnt);
  if (status !== BadgeStatusValue.Warning && status !== BadgeStatusValue.Critical && hidden) {
    return BadgeStatusValue.New;
  }
  return status;
};

export const getTiersFromIds = (
  tiers: LoyaltyApi.Tier.Type[],
  tierIds: LoyaltyApi.Tier.Type["id"][]
): LoyaltyApi.Tier.Type[] => {
  const newReturnedTiers = tierIds.map(currentTierId =>
    tiers.find(currentTier => currentTier.id === currentTierId)
  );
  return newReturnedTiers.filter((tier): tier is LoyaltyApi.Tier.Type => tier !== undefined);
};

/**
 * Takes in array of tiers and array of tiers relevant to the current row
 * and returns the text to display in the rewards table
 */
export const getTierText = (rowTiers: LoyaltyApi.Tier.Type[]): string => {
  const sortedTiers = sortTiersByRank(rowTiers);
  const includesFirstTier = sortedTiers.some(tier => tier.tierRank === 1);
  if (sortedTiers.length === 1) {
    return `${sortedTiers[0].tierName} tier`;
  }

  return includesFirstTier ? `All customers` : `${sortedTiers[0].tierName} tier and up`;
};
