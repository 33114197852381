import { z } from "zod";

import { LoyaltyApi } from "./api";

export namespace LoyaltyValidation {
  export namespace Event {
    export const schema = LoyaltyApi.Event.schema;
    export type Type = z.infer<typeof schema>;
  }

  export namespace Variant {
    export const schema = LoyaltyApi.RewardProduct.schema.omit({
      variant: true,
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Tier {
    export const schema = LoyaltyApi.Tier.schema;
    export type Type = z.infer<typeof schema>;
  }

  export namespace PriceRule {
    export const schema = LoyaltyApi.PriceRule.schema.omit({
      id: true,
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Incentive {
    export const schema = LoyaltyApi.Incentive.schema
      .omit({
        priceRule: true,
      })
      .merge(
        z.object({
          priceRule: PriceRule.schema,
        })
      );
    export type Type = z.infer<typeof schema>;
  }

  export namespace Bonus {
    export const schema = LoyaltyApi.Bonus.schema;
    export type Type = z.infer<typeof schema>;
  }

  export namespace Referral {
    export const schema = LoyaltyApi.Referral.schema
      .omit({
        priceRule: true,
        generatedDiscountCodes: true,
      })
      .merge(
        z.object({
          priceRule: PriceRule.schema.extend({
            value: z.string().refine(
              input => {
                const parsedInput = z.coerce.number().int().nonpositive().safeParse(input);
                return parsedInput.success;
              },
              { message: "Discount must be a negative integer" }
            ),
          }),
        })
      );
    export type Type = z.infer<typeof schema>;
  }

  export namespace Settings {
    export const schema = z.object({
      /**
       * Limits the number of times a specific reward can be applied to an upcoming order
       * 0 : No limits
       * 1+: Limited to a maximum of the specified amount.
       */
      limitUse: z.number().min(0, { message: "Limit use should be 0 or greater. 0 for no limit" }),
      stackableDiscountCodes: z.boolean(),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Redeem {
    export namespace Variant {
      export const schema = z.object({
        variantId: z.string().uuid(),
        cpsId: z.string().uuid(),
      });
      export type Type = z.infer<typeof schema>;
    }
    export namespace Incentive {
      export const schema = z.object({
        incentiveId: z.string(),
        cpsId: z.string().uuid(),
      });
      export type Type = z.infer<typeof schema>;
    }
  }

  export namespace Program {
    export const schema = LoyaltyApi.Program.schema
      .omit({
        updatedDate: true,
        status: true,
        variants: true,
        referralProgram: true,
        incentives: true,
        otpEnabled: true,
        tiersEnabled: true,
      })
      .merge(
        z.object({
          variants: z.array(Variant.schema),
          referralProgram: Referral.schema.nullable(),
          incentives: z.array(Incentive.schema),
          tiers: z.array(Tier.schema),
        })
      );
    export type Type = z.infer<typeof schema>;
  }
}
