import { LoyaltyApi } from "@smartrr/shared/interfaces/loyalty/api";

/**
 * Reads max points of current tiers and selects the next default tier to add
 * If there is no default tier that is higher than the current max points
 * it looks for the lowest possible default tier to add
 */
export const getNextHighestTierToAdd = (
  defaultTiers: LoyaltyApi.Tier.Type[],
  tiers: LoyaltyApi.Tier.Type[]
): LoyaltyApi.Tier.Type => {
  const maxCurrentPoints = Math.max(...tiers.map(tier => tier.minimumPoints), 0);

  const tiersAboveCurrentMaxPoints = defaultTiers
    .filter(tier => tier.minimumPoints > maxCurrentPoints)
    .filter(
      defaultTier =>
        !tiers.some(
          currentTier =>
            currentTier.tierName === defaultTier.tierName || currentTier.tierColor === defaultTier.tierColor
        )
    );

  const tiersWithNoEqualValues = defaultTiers.filter(
    defaultTier =>
      !tiers.some(
        currentTier =>
          currentTier.minimumPoints === defaultTier.minimumPoints ||
          currentTier.tierName === defaultTier.tierName ||
          currentTier.tierColor === defaultTier.tierColor
      )
  );

  let addedTier: LoyaltyApi.Tier.Type;

  if (tiersAboveCurrentMaxPoints.length) {
    addedTier = tiersAboveCurrentMaxPoints.reduce((prev, curr) =>
      curr.minimumPoints < prev.minimumPoints ? curr : prev
    );
  } else {
    addedTier = tiersWithNoEqualValues.reduce((prev, curr) =>
      curr.minimumPoints > prev.minimumPoints ? curr : prev
    );
  }

  return addedTier;
};
